import { DashboardOutlined ,InboxOutlined,ControlOutlined,DollarOutlined, ThunderboltOutlined, GiftOutlined, VideoCameraOutlined, UserAddOutlined} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'


const dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'home',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [],
  permissions:['common']
  
}]
const bonusTree = [{
  key: 'bonuses',
  path: `${APP_PREFIX_PATH}/bonuses`,
  title: 'Bonus',
  icon: GiftOutlined,
  breadcrumb: false,
  submenu: [],
  permissions:['common']
}]
const projectsNavTree = [{
  key: 'campaigns',
  path: `${APP_PREFIX_PATH}/campaigns`,
  title: 'My Videos',
  icon: InboxOutlined,
  breadcrumb: false,
  submenu: [],
  permissions:['common']
}]
const templatesNavTree = [{
  key: 'templates',
  path: `${APP_PREFIX_PATH}/templates`,
  title: 'Templates',
  icon: ThunderboltOutlined,
  breadcrumb: false,
  submenu: [],
  permissions:['common']
}]
const tutorialsNavTree = [{
  key: 'tutorials',
  path: `${APP_PREFIX_PATH}/tutorials`,
  icon: VideoCameraOutlined,
  breadcrumb: false,
  isGroupTitle: false,
  title: 'Tutorials',
  submenu: [],
  permissions:['common']
}]
const teamsNavTree = [{
  key: 'teams',
  path: `${APP_PREFIX_PATH}/teams`,
  icon: UserAddOutlined,
  breadcrumb: false,
  isGroupTitle: false,
  title: 'Teams',
  submenu: [],
  permissions:['common']
}]


const whitelabelTree  = [{
  key: 'whitelabel',
  path: `${APP_PREFIX_PATH}/whitelabel/users`,
  title: 'Whitelabel',
  icon: ControlOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'whitelabelsettings',
      path: `${APP_PREFIX_PATH}/whitelabel/settings`,
      title: 'Settings',
      icon: '',
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'whitelabelusers',
      path: `${APP_PREFIX_PATH}/whitelabel/users`,
      title: 'Users',
      icon: '',
      breadcrumb: false,
      submenu: []
    }],
    permissions:['bundle','agency-25','agency-100']
}]


const purchases = [{
  key: 'purchases',
  path: `${APP_PREFIX_PATH}/purchase`,
  title: 'My Purchases',
  icon: DollarOutlined,
  breadcrumb: false,
  submenu: [],
  permissions:['common']
}]

const navigationConfig = [
  ...dashBoardNavTree,
  // ...projectsNavTree,
  ...templatesNavTree,
  ...whitelabelTree,
  ...teamsNavTree,
  // ...bonusTree,
  ...tutorialsNavTree,
  ...purchases
  
  
]

export default navigationConfig;
