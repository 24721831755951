import { Modal, Button, Row, Col, Input, Form } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />;

export const IdeaModal = ({
  topics,
  onSelectClick,
  selectBtnLoading,
  isIdeaModalOpen,
  onClose,
}) => {
  return (
    <div>
      <Modal
        destroyOnClose
        open={isIdeaModalOpen}
        width={700}
        footer={null}
        onCancel={onClose}
        maskStyle={{ background: "rgba(0,0,0,.8)" }}
      >
        <h2 className="text-center mt-3 pb-4">
          Generated Video Ideas and Titles
        </h2>
        {!selectBtnLoading ? (
          <Row gutter={10}>
            {topics == null ? (
              <Col> </Col>
            ) : (
              topics.map((elm) => (
                <Col key={elm.id}>
                  <Form.Item>
                    <Input
                      value={elm}
                      style={{ width: 520 }}
                      placeholder="Idea"
                    />
                    <Button
                      type="primary"
                      icon={<ArrowRightOutlined />}
                      style={{ marginLeft: 8 }}
                      onClick={() => onSelectClick(elm)}
                    >
                      Select
                    </Button>
                  </Form.Item>
                </Col>
              ))
            )}
          </Row>
        ) : (
          <Spin indicator={Icon} />
        )}
      </Modal>
    </div>
  );
};
