import { Input, Select, Button, Tooltip } from "antd";
import { useState } from 'react';
import languagesArray from './language.json'

const { TextArea } = Input;
const { Option } = Select;




export const OutlineCustomeInput = ({
    language,
    handleInputChange,
    inputValue,
    placeholder,
    handleNextClick
}) => {
    // console.log(language)
    
    const [selectedLanguage, setSelectedLanguage] = useState(language);
    const handleLanguageChange = (value) => {
        setSelectedLanguage(value);

    }

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleNextClick(selectedLanguage)
        }

    }

    return (
        <div style={{
            position: 'relative',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px'
        }}>
            <TextArea
                rows={10}
                value={typeof inputValue === 'object' ? inputValue.join('\n') :inputValue}
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                placeholder={placeholder}
                style={{
                    width: '100%',
                    marginBottom: '10px',
                    border: 'none',
                    outline: 'none',
                }}
            />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Select
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    style={{ width: '180px' }}
                    placeholder="Language"
                    defaultValue={selectedLanguage}
                >
                    {languagesArray.map(lang => (
                        <Option key={lang.code} value={lang.code}>{lang.name}</Option>
                    ))}
                </Select>

                {/* <Tooltip title={selectedLanguage!==null ? "Select a language first" : ""}> */}
                <span>
                    <Button type="primary" disabled={!selectedLanguage} onClick={() => handleNextClick(selectedLanguage)} >
                        Next
                    </Button>
                </span>
                {/* </Tooltip> */}
            </div>
        </div>
    );
}
