
import customFetch from 'auth/FetchInterceptor'
const urlContentService = {};


urlContentService.getChunks = function (data) {
  return customFetch({
    url: '/ai_chunk_from_url',
    method: 'post',
    data: data
  })
}

urlContentService.getSummary = function (data) {
  return customFetch({
    url: '/ai_summarizer',
    method: 'post',
    data: data
  })
}

urlContentService.getAzSummary = function (data) {
  return customFetch({
    url: '/chatbot/ai-summarization',
    method: 'post',
    data: data
  })
}


urlContentService.getSummarizeTextResult = function (data) {
  return customFetch({
    url: '/chatbot/ai-summarization-result',
    method: 'post',
    data: data
  })
}

export default urlContentService;