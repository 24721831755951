import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Spin } from 'antd';
import mediaService from 'services/MediaService';

const { Option } = Select;

export const LicenseDownload = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [musicData, setMusicData] = useState([]);
  const [selectedLicenseUrl, setSelectedLicenseUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchMusicData = () => {
    setIsLoading(true);
    mediaService.getMusic().then((res) => {
        setMusicData(res);
        setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
            }
        )
  
  };


  const showModal = () => {
    setIsModalVisible(true);
    fetchMusicData(); // Fetch data when modal opens
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleMusicChange = value => {
    const selectedMusic = musicData.find(music => music.id.toString() === value.toString());
    setSelectedLicenseUrl(selectedMusic?.license || '');
  };

  const handleDownloadLicense = () => {
    if (selectedLicenseUrl) {
      window.open(selectedLicenseUrl, '_blank');
    }
  };

  console.log('Rendering music data:', musicData); // Log the data when the component renders


  return (
    <>
      <Button type="link" onClick={showModal}>
        Download Music License
      </Button>
      <Modal
        title="Download Music License"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleDownloadLicense} disabled={!selectedLicenseUrl}>
            Download
          </Button>,
        ]}
      >
        {isLoading ? (
          <Spin />
        ) : (
          <Select
            showSearch
            placeholder="Select a music track"
            optionFilterProp="children"
            onChange={handleMusicChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {musicData.map(music => (
              <Option key={music.id.toString()} value={music.id.toString()}>
                {music.name}
              </Option>
            ))}
          </Select>
        )}
      </Modal>
    </>
  );
};

export default LicenseDownload;
