import { Modal } from "antd"
import TemplateList from "views/app-views/templates/TemplateList"

export const TemplateListModal = ({isModalOpen,handleOnClose, userData})=>{
    return (<Modal
        destroyOnClose
        width={"70vw"}
        centered
        open={isModalOpen}
        onCancel={handleOnClose}
        footer={null}
        maskStyle={{ background: "rgba(0,0,0,.8)" }}
    >
        <TemplateList userData={userData}/>
    </Modal>)
}