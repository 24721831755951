import { Input, Select, Button, Tooltip } from "antd";
import { useState } from 'react';
import languagesArray from './language.json'
import language from "react-syntax-highlighter/dist/esm/languages/hljs/1c";

const { TextArea } = Input;
const { Option } = Select;



export const IdeaCustomInput = ({
    handleInputChange,
    inputValue,
    placeholder,
    handleNextClick
}) => {
    const [selectedLanguage, setSelectedLanguage] = useState('english');

    const handleLanguageChange = (value) => {
        setSelectedLanguage(value);

    }

    const handleInputKey=(e)=>{
        if(e.key==='Enter'){
            handleNextClick(selectedLanguage)
        }
    
    }

    return (
        <div style={{
            position: 'relative',
            padding: '10px',
            // border: '1px solid #ccc', 
            borderRadius: '4px'
        }}>
            <Input
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleInputKey}
                placeholder={placeholder}
                style={{
                    width: '100%',
                    marginBottom: '10px',
                    border: 'none',
                    outline: 'none',
                }}
            />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Select
                    size="small"
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    style={{ width: '180px' }}
                    placeholder="Language"
                >
                    {languagesArray.map(lang => (
                        <Option key={lang.code} value={lang.code}>{lang.name}</Option>
                    ))}
                </Select>

                <Tooltip title={selectedLanguage === null ? "Select a language first" : ""}>

                    <span>
                        <Button type="primary" disabled={!selectedLanguage} onClick={() => handleNextClick(selectedLanguage)} >
                            Next
                        </Button>
                    </span>
                </Tooltip>
            </div>
        </div>
    );
}
