import fetch from "auth/FetchInterceptor";

const campaignService = {};

// campaignService.create = function (data) {
//     return fetch({
//      url: '/create_project',
//      method: 'post',
//      data: data,
//    });
//  };

//  campaignService.getByID = function (data) {
//     return fetch({
//      url: '/project_record',
//      method: 'post',
//      data: data,
//    });
//  };

//  campaignService.update = function (data) {
//   return fetch({
//    url: '/update_project',
//    method: 'post',
//    data: data,
//  });
// };

// campaignService.getProjects =function(){
//   return fetch({
//     url: '/all_campaigns',
//     method: 'get'
//   });
// }
campaignService.getCampaigns =function(id){
  return fetch({
    url: `/campaigns`,
    method: 'get'
  });
}

campaignService.createCampaign =function(data){
  return fetch({
    url: `/campaigns`,
    method: 'post',
    data: data
  });
}

campaignService.getCampaignById =function(id){
  return fetch({
    url: `/campaign/${id}`,
    method: 'get'
  });
}

campaignService.updateCampaign =function(id,data){
  return fetch({
    url: `/campaign/${id}`,
    method: 'put',
    data: data
  });
}
// campaignService.archiveProject =function(id){
//   return fetch({
//     url: `/archive_campaign/${id}`,
//     method: 'get'
//   });
// }

campaignService.cloneCampaign =function(id){
  return fetch({
    url: `/campaign/clone/${id}`,
    method: 'post'
  });
}

campaignService.deleteCampaign =function(id){
  return fetch({
    url: `/campaign/${id}`,
    method: 'delete'
  });
}



 export default campaignService
 

