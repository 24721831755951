import { getOutlineFromKeyword, getTopicFromKeyword, getChatOutline } from "utils/common/aiDataHelper";
import useUserData from 'utils/hooks/useUserData';
import { createProject } from "utils/common/projectHelper";
import { getChunkFromUrl } from "utils/common/urlDataHelper";
import { Navigate } from "react-router-dom";
import { createCampaign } from "utils/api/campaign";
import { nameGenerator } from "utils/common/helper";

export const apiCallsForScenarios = async (apiname, apiData) => {
    let data = {}
    switch (apiname) {
        case 'ideaAPI':
            data = {
                videoTitle: apiData.payload,
                lang : apiData.lang
            }

            const response = await getTopicFromKeyword(data);
            const respai = {
                response: response
            }
            return respai;


        case 'outlineAPI':
            data = {
                videoTitle: apiData.payload,
                lang : apiData.lang
            }
            const payload = {
                project_name : nameGenerator(10)

            }
            const aiproject = await createCampaign(payload);
            
            try {
                const outLineResp = await getChatOutline(data);
                // console.log("Outline Response:", outLineResp);  // Logging the response
        
                const respoutline = {
                    project: aiproject,
                    response: outLineResp
                }
                return respoutline;
            } catch(error) {
                console.error("Error getting outline:", error);
                throw error; // If you want the error to propagate
            }
            
       
        case 'urlAPI':
            const payloadOutline = {
                project_name : nameGenerator(10)

            }

            const project = await createCampaign(payloadOutline)
            const chunk = await getChunkFromUrl({ url: apiData.payload })
            const resp = {
                project: project,
                response: chunk
            }
            return resp;
        case 'textAPI':
            const payloadText = {
                project_name : nameGenerator(10)

            }
            const projectText = await createCampaign(payloadText)
            const respText = {
                project: projectText,
                response: apiData.payload
            }
            return respText;
        case 'scratchAPI':
            // console.log(apiData)
            
            const scratchResp = await createCampaign({  project_name:apiData.payload })
            const ScratchResp = {
                project: scratchResp,
                response: scratchResp
            }
            return ScratchResp;

        default:
            break;
    }
}

