import fetch from "auth/FetchInterceptor";

const mediaService = {};

mediaService.uploadMedia = function (formdata) {
  return fetch({
    url: "/media/create",
    method: "post",
    data: formdata,
  });
};

mediaService.getMedia =function(){
    return fetch("/media")
}

mediaService.deleteMedia =function(id){
    return fetch({
        url: "/media/"+id,
        method: "delete",
      });
}

mediaService.getMusic =function(){
  return fetch("/music")
}
export default mediaService;
