
import { Button, Col, Modal, Row } from "antd";
import React, { useContext } from "react";
import { OutlineModal } from "components/layout-components/OutlineModal";
import {
  LinkOutlined,
  ThunderboltOutlined,
  EditOutlined,
  CrownOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getChatOutline,
} from "utils/common/aiDataHelper";
import { IdeaModal } from "components/layout-components/IdeaModal";
import { ScratchModal } from "./ScratchModal";
import { TemplateListModal } from "./TemplateListModal";
import { createCampaign } from "utils/api/campaign";
import { UserContext } from "context/UserContext";
import { AIModal } from "./AIModal";
import LicenseDownload from "./LicenseDownload";
export const ChooseSource = () => {
  const navigate = useNavigate();
  const { userProfile, setUserProfile } = useContext(UserContext);

  const [isScratchModalOpen, setScratchModalOpen] = useState(false);
  const [isScratchSubmitBtnLoading, setScratchSubmitBtnLoading] = useState()
  const [isTemplateModalOpen, setTemplateModalOpen] = useState(false);
  const [isAIModalOpen, setAIModalOpen] = useState(false);
  const [isAISubmitBtnLoading, setAISubmitBtnLoading] = useState(false);
  const [isModalBtnLoading, setModalBtnLoading] = useState(false);
  const [isIdeaModalBtnLoading, setIdeaModalBtnLoading] = useState(false);

  const handleSubmitProjectFromScratch = (values) => {
    setModalBtnLoading(true);
    console.log(values)
    setScratchSubmitBtnLoading(true)

    createCampaign(values).then((response) => {
      setIdeaModalBtnLoading(false);
      const project_id = response.project_id
      navigate(`/studio?projectId=${project_id}`);

    })
  }


  const handleSubmitProjectFromAI = (values) => {
    setAISubmitBtnLoading(true)
    createCampaign(values).then((response) => {
      setIdeaModalBtnLoading(false);
      const project_id = response.project_id
      navigate(`/chatbot?projectId=${project_id}`);

    })

  }















  return (
    <div className="projects">
      
      <AIModal
        isModalOpen={isAIModalOpen}
        handleOnClose={() => setAIModalOpen(false)}
        handleSubmit={handleSubmitProjectFromAI}
        isLoading={isAISubmitBtnLoading}
      />



      <ScratchModal
        isModalOpen={isScratchModalOpen}
        handleOnClose={() => setScratchModalOpen(false)}
        handleSubmit={handleSubmitProjectFromScratch}
        isLoading={isScratchSubmitBtnLoading}
      />

      <TemplateListModal
        userData={userProfile}
        isModalOpen={isTemplateModalOpen}
        handleOnClose={() => { setTemplateModalOpen(false) }}
      />
      <Row gutter={24} justify={"space-between"}>
      <Col>

        <Button
          type="primary"
          onClick={() => {
           
            setAIModalOpen(true)

          }}
          style={{ marginLeft: '10px' }}
          
          id="ai"
        >
          <CrownOutlined />
          Create Using A.I.
        </Button>


        <Button
          onClick={() => {
            setTemplateModalOpen(true)
          }}
          style={{ marginLeft: '10px' }}
         
          id="text"
        >

          <ThunderboltOutlined />
          Create From Template

        </Button>

        <Button
          onClick={() => {
            setScratchModalOpen(true)
          }}
          style={{ marginLeft: '10px' }}
         
          id="pdf"
        >

          <EditOutlined />

          Create From Scratch

        </Button>
        </Col>
        <Col>

        <LicenseDownload />
        </Col>
      </Row>
    </div>
  );
}
