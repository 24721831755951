import { genetateRestoryId, nameGenerator } from "./helper.js";
import projectService from "services/CampaignService.js";
import { PROJECT_JSON } from "./helper.js";

export const createProject = (data) => {
  return new Promise((resolve, reject) => {
    PROJECT_JSON.project_id = genetateRestoryId();
    PROJECT_JSON.project_name = data.projectName ?? nameGenerator(10);
    PROJECT_JSON.input_data = data.INITIAL_EDITS ? data.INITIAL_EDITS : [];
    PROJECT_JSON.scene_data = data.scene_data;
    PROJECT_JSON.user_id = data.userData.id;
    PROJECT_JSON.source = data.source?data.source:'Scratch';
    PROJECT_JSON.project_type =data.projectType?data.projectType:'AIVB';
    const projectData = JSON.stringify(PROJECT_JSON);
    projectService
      .create(projectData)
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        reject();
      });
  });
};

export const getProjectById = (data) => {
  return new Promise((resolve, reject) => {
    const projectData = {
      project_id: data.project_Id,
    };

    projectService
      .getByID(projectData)
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        reject();
      });
  });
};

export const updateProjectById = (data) => {
  return new Promise((resolve, reject) => {
    PROJECT_JSON.project_id = data.projectId;
    PROJECT_JSON.project_name = data.projectName;
    PROJECT_JSON.scene_data = data.scene_data?data.scene_data:[];
    PROJECT_JSON.input_data = data.input_data;
    projectService
      .update(PROJECT_JSON)
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        reject();
      });
  });
};
