import React, { useContext } from 'react';
import { Dropdown, Avatar } from 'antd';
import { useDispatch } from 'react-redux'
import { 
	EditOutlined, 
	SettingOutlined, 
	ShopOutlined, 
	QuestionCircleOutlined, 
	LogoutOutlined 
} from '@ant-design/icons';
import NavItem from './NavItem';
import Flex from 'components/shared-components/Flex';
import { signOut } from 'store/slices/authSlice';
import styled from '@emotion/styled';
import { FONT_WEIGHT, MEDIA_QUERIES, SPACER, FONT_SIZES } from 'constants/ThemeConstant'
import WhiteLabelContext from 'context/WhitelabelContext';
import md5 from 'md5';

const Icon = styled.div(() => ({
	fontSize: FONT_SIZES.LG
}))

const Profile = styled.div(() => ({
	display: 'flex',
	alignItems: 'center'
}))

const UserInfo = styled('div')`
	padding-left: ${SPACER[2]};

	@media ${MEDIA_QUERIES.MOBILE} {
		display: none
	}
`

const Name = styled.div(() => ({
	fontWeight: FONT_WEIGHT.SEMIBOLD
}))

const Title = styled.span(() => ({
	opacity: 0.8
}))

const MenuItem = ({ path, label, icon, external }) => {
	if(external) {
		return (
			<Flex as="a" href={path} target="_blank" rel="noopener noreferrer" alignItems="center" gap={SPACER[2]}>
				<Icon>{icon}</Icon>
				<span>{label}</span>
			</Flex>
		)
	} else {
		return (
			<Flex as="a" href={path} alignItems="center" gap={SPACER[2]}>
				<Icon>{icon}</Icon>
				<span>{label}</span>
			</Flex>
		)
	}
}

const MenuItemSignOut = (props) => {
	const dispatch = useDispatch();

	const handleSignOut = () => {
		dispatch(signOut())
	}

	return (
		<div onClick={handleSignOut}>
			<Flex alignItems="center" gap={SPACER[2]} >
				<Icon>
					<LogoutOutlined />
				</Icon>
				<span>{props.label}</span>
			</Flex>
		</div>
	)
}

const getGravatarUrl = (email) => {
	const hash = md5(email.toLowerCase().trim());
	return `https://www.gravatar.com/avatar/${hash}`;
};

export const NavProfile = ({mode}) => {
	const profile = JSON.parse(localStorage.getItem('profile'));
	const whiteLabel = useContext(WhiteLabelContext);

	const items = [
		{
			key: 'profile',
			label: <MenuItem path="/profile" label="Edit Profile" icon={<EditOutlined />} />,
		},
		{
			key: 'Change Password',
			label: <MenuItem path="/change-password" label="Change Password" icon={<SettingOutlined />} />,
		},
		{
			key: 'Privacy',
			label: <MenuItem path="/privacy" label="Privacy" icon={<ShopOutlined />} />,
		},
		{
			key: 'Help Center',
			label: <MenuItem 
				external 
				path={whiteLabel?.support_url || "https://aivideobuilder.com/support"} 
				label="Help Center" 
				icon={<QuestionCircleOutlined />} 
			/>,
		},
		{
			key: 'Sign Out',
			label: <MenuItemSignOut label="Sign Out" />,
		}
	];

	return (
		<Dropdown placement="bottomRight" menu={{items}} trigger={["click"]}>
			<NavItem mode={mode}>
				<Profile>
					<Avatar src={getGravatarUrl(profile?.email || "")} />
					<UserInfo className="profile-text">
						<Name>{profile?.name} ▼</Name>
						{/* <Title>Frontend Developer</Title> */}
					</UserInfo>
				</Profile>
			</NavItem>
		</Dropdown>
	);
}

export default NavProfile;