import fetch from "auth/FetchInterceptor";
const synthesisService = {};

synthesisService.getSynthesis = function (data) {
    return fetch({
        url: "/synthesis",
        method: "post",
        data: data,
    });
    };


export default synthesisService;