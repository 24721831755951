import React, { useState, useEffect } from 'react';
import { List, Button, Input, Space, Form, Row, Col, Typography } from 'antd';
import { scenarios } from './config';
import { apiCallsForScenarios, isValid } from './apiCalls';
import useUserData from 'utils/hooks/useUserData';
import { useNavigate } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import { OutlineCustomeInput } from './OutlineCustomeInput';
import { IdeaCustomInput } from './IdeaCustomInput';
import { AICard, URLCard, TemplateCard, ScratchCard, IdeaCards, TextCard } from './sourcecards';



const { Title } = Typography;

const ChatBox = ({ setModelOpen }) => {
    const { userData } = useUserData();
    const navigate = useNavigate();
    const [chatHistory, setChatHistory] = useState([
        {
            message: scenarios[0].message,
            sender: 'system'
        }
    ]);

    const chatEndRef = React.useRef(null);

    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [currentScenario, setCurrentScenario] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [isTyping, setIsTyping] = useState(true);
    const [optionValue, setOptionValue] = useState("")
    const [apiResponse, setApiResponse] = useState("")

    useEffect(() => {
        if (chatEndRef.current) {
            setTimeout(() => {
                chatEndRef.current.scrollIntoView({ behavior: "smooth" });
            }, 100);
        }
    }, [chatHistory]);




    useEffect(() => {

        setTimeout(() => {
            // console.log(currentScenario)
            setIsTyping(false);
            setCurrentScenario(scenarios[1]);
            addMessageToChatHistory(scenarios[1].message, 'system');

        }, scenarios[1].delay);
    }, []);

    useEffect(() => {
        if (currentScenario && currentScenario.id === 1) {
            setIsTyping(true);
            setTimeout(() => {
                setIsTyping(false);
                const nextScenario = scenarios.find(s => s.id === currentScenario.next);
                setCurrentScenario(nextScenario);
                addMessageToChatHistory(nextScenario.message, 'system');

            }, currentScenario.delay);
        }
    }, [currentScenario]);

    useEffect(() => {
        if (currentScenario && currentScenario.id !== 2 && currentScenario.id !== 1) {
            setIsTyping(true);
            if (currentScenario.message === undefined) {
                setIsTyping(false)
            }
            setTimeout(async () => {
                if (currentScenario.apiCall) {
                    const resp = await apiCallsForScenarios(currentScenario.apiName, optionValue)
                    setApiResponse(resp)
                    setIsTyping(false);
                    if (currentScenario.isNavigate) {
                        if (currentScenario.isParam) {
                            navigate(`${currentScenario.urlstart}${resp.project.project_id}${currentScenario.urlEnd}${currentScenario.isParam}${selectedLanguage}`,
                                {
                                    state: resp.response.chunks
                                }

                            )
                        } else {
                            navigate(`${currentScenario.urlstart}${resp.project.project_id}${currentScenario.urlEnd}`,
                                {
                                    state: resp.response.chunks
                                }

                            )
                        }

                        if (typeof setModelOpen === "function") {
                            setModelOpen(false);
                        }
                    }
                    const next = scenarios.find(s => s.id === currentScenario.next);
                    if (currentScenario.optionNext) {
                        const newOptions = resp.response.map(item => ({
                            label: item,
                            next: currentScenario.optionNext,
                            componentType: currentScenario.OptionComponentType
                        }));
                        const nextScenarioTemp = {
                            ...next,
                            responseType: 'button',
                            options: newOptions
                        }
                        setCurrentScenario(nextScenarioTemp);
                        return
                    } else {
                        setCurrentScenario(next)
                        setInputValue(resp.response)

                    }

                }
                const nextScenario = scenarios.find(s => s.id === currentScenario.next);
                if (currentScenario.message) {
                    setTimeout(() => {
                        setIsTyping(false);
                        addMessageToChatHistory(currentScenario.message, 'system');
                        setCurrentScenario(nextScenario)
                    }, currentScenario.delay);

                }


                if (nextScenario && nextScenario.apiCall) {
                    setCurrentScenario(nextScenario)
                }

                if (!currentScenario.apiCall && currentScenario.isNavigate) {
                    setIsTyping(true)
                    setTimeout(() => {
                        setIsTyping(false)
                        if (currentScenario.urlstart) {
                            if (currentScenario.isParam) {

                                navigate(`${currentScenario.urlstart}${apiResponse.project.project_id}${currentScenario.urlEnd}${currentScenario.isParam}${selectedLanguage}`, {
                                    state: inputValue
                                })
                            } else {
                                navigate(`${currentScenario.urlstart}${apiResponse.project.project_id}${currentScenario.urlEnd}`, {
                                    state: inputValue
                                })
                            }

                            if (typeof setModelOpen === "function") {
                                setModelOpen(false);
                            }
                        } else {
                            navigate(`${currentScenario.url}`)
                            if (typeof setModelOpen === "function") {
                                setModelOpen(false);
                            }
                        }

                    }, 2000);

                }
            }, currentScenario.delay);

        }

    }, [currentScenario])




    const addMessageToChatHistory = (message, sender) => {
        setChatHistory(prev => [...prev, { message, sender }]);
    };

    const handleButtonClick = (option) => {
        // Add the clicked option label to the chat history as a user message
        addMessageToChatHistory(option.label, 'user');

        // Find the next scenario based on the selected option's `next` value
        const nextScenario = scenarios.find(s => s.id === option.next);
        setCurrentScenario(nextScenario);


        setOptionValue({
            userData: userData,
            payload: option.label,
            lang: selectedLanguage
        })
    };


    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyPress = async (e) => {
        if (e.key === 'Enter' && currentScenario) {
            addMessageToChatHistory(inputValue, 'user');
            const nextScenario = scenarios.find(s => s.id === currentScenario.next);
            setCurrentScenario(nextScenario)
            setOptionValue({
                userData: userData,
                payload: inputValue
            })

        }
    };

    const handleNextClick = (language) => {
        // console.log("language",language)
        setSelectedLanguage(language);

        addMessageToChatHistory(inputValue, 'user');
        const nextScenario = scenarios.find(s => s.id === currentScenario.next);
        setCurrentScenario(nextScenario)
        setOptionValue({
            userData: userData,
            payload: inputValue,
            lang: language
        })


    }


    const handlUrlClick = (values) => {
        addMessageToChatHistory(values.url, 'user');
        const next = scenarios.find(s => s.id === currentScenario.next);
        setCurrentScenario(next);
        setOptionValue({
            userData: userData,
            payload: values.url,
            lang: selectedLanguage
        })
    }


    return (
        <div className="chat-wrapper">


            {/* <BlobAnimation /> */}

            <div className="chat-container ">

                <List
                    dataSource={chatHistory}
                    renderItem={item => (
                        <List.Item style={{ justifyContent: item.sender === 'user' ? 'flex-end' : 'flex-start' }}>
                            <div className={`message ${item.sender}`}>
                                <p style={{ whiteSpace: "pre-line", textAlign: "left" }}>{item.message}</p>
                            </div>
                        </List.Item>
                    )}
                />
                <div ref={chatEndRef} />

                {isTyping && (
                    <div className="typing-animation">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                )}
                {
                    currentScenario && currentScenario.responseType === 'button' && (
                        <div className='source_cards'>
                            {currentScenario.options.some(option => option.componentType === 'IdeaCards') && (
                                <Title level={5} className='mt-3'>Select Any Idea</Title>
                            )}
                            <Row gutter={4}>
                                {currentScenario.options.map(option => {
                                    let component;
                                    switch (option.componentType) {
                                        case 'AICard':
                                            component = <AICard key={option.label} onClick={() => handleButtonClick(option)} />;
                                            break;
                                        case 'URLCard':
                                            component = <URLCard key={option.label} onClick={() => handleButtonClick(option)} />;
                                            break;
                                        case 'TemplateCard':
                                            component = <TemplateCard key={option.label} onClick={() => handleButtonClick(option)} />;
                                            break;
                                        case 'ScratchCard':
                                            component = <ScratchCard key={option.label} onClick={() => handleButtonClick(option)} />;
                                            break;
                                        case 'TextCard':
                                            component = (<TextCard key={option.label} onClick={() => handleButtonClick(option)} />);
                                            break;
                                        case 'IdeaCards':
                                            component = <IdeaCards key={option.label} onClick={() => handleButtonClick(option)} data={option.label} />;
                                            return <Col span={24} key={option.label}>{component}</Col>;

                                        default:
                                            component = (
                                                <Button className='ml-2' key={option.label} onClick={() => handleButtonClick(option)}>
                                                    {option.label}
                                                </Button>
                                            );
                                    }
                                    return <Col flex="auto"  key={option.label}>{component}</Col>;
                                })}
                            </Row>
                        </div>
                    )
                }



                {currentScenario && currentScenario.responseType === 'customeOutlineInput' && (
                    <div className="user-input">

                        <OutlineCustomeInput
                            inputValue={inputValue}
                            handleInputChange={handleInputChange}
                            placeholder={currentScenario.message}
                            handleNextClick={handleNextClick}
                            language={selectedLanguage}
                        />
                    </div>
                )}
                {currentScenario && currentScenario.responseType === 'text' && (
                    <div className="user-input">

                        <TextArea
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyPress={handleInputKeyPress}
                            placeholder={currentScenario.message}
                        />
                    </div>
                )}


                {currentScenario && currentScenario.responseType === 'input' && (
                    <div className="user-input">

                        <Input
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyPress={handleInputKeyPress}
                            placeholder={currentScenario.message}
                        />

                    </div>
                )}

                {currentScenario && currentScenario.responseType === 'ideaCustomInput' && (
                    <div className="user-input">

                        <IdeaCustomInput
                            inputValue={inputValue}
                            handleInputChange={handleInputChange}
                            placeholder={currentScenario.message}
                            handleNextClick={handleNextClick}
                        />
                    </div>
                )}


                {currentScenario && currentScenario.responseType === 'url' && (
                    <Form onFinish={handlUrlClick}>
                        <div className="user-input">
                            <Form.Item
                                name="url"
                                className="mb-100"
                                style={{ marginTop: 8, width: "100%" }}
                                rules={[
                                    { required: true, message: "Please enter url." },
                                    {
                                        type: "url",
                                        warningOnly: false,
                                        message: "Please enter a valid url.",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Paste your URL here & we will fetch the data on the scraper"
                                />
                            </Form.Item>
                        </div>
                    </Form>
                )}

                {currentScenario && currentScenario.responseType === 'none' && (
                    <></>
                )}
            </div>
        </div>
    );
};


export default ChatBox;
