import fetch from 'auth/FetchInterceptor'

const ProfileService = {}

ProfileService.getProfile = function () {
    return fetch({
        url: '/profile',
        method: 'get'
    })
}

ProfileService.updateProfile = function (data) {
    return fetch({
        url: '/profile',
        method: 'put',
        data: data
    })
}
    
ProfileService.getPlans = function () {
    return fetch({
      url: "/plans",
      method: "get",
    });
  };


export default ProfileService;