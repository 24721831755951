import { Modal, Form, Row, Typography, Input, Button } from "antd"

const { Title } = Typography;


export const AIModal = ({
    isModalOpen,
    handleOnClose,
    handleSubmit,
    isLoading

}) => {
    return (<Modal
        destroyOnClose
        // width={"50%"}
        centered
        open={isModalOpen}
        onCancel={handleOnClose}
        footer={null}
        maskStyle={{ background: "rgba(0,0,0,.8)" }}
    >
        <div>
            <Form onFinish={handleSubmit}
            disabled={isLoading}
            >
                <Row>
                    <Title level={2}>Start with an AI</Title>

                    <Form.Item
                        name="project_name"
                        className="mb-100"
                        style={{ width: "100%" }}
                        rules={[
                            { required: true, message: "Please enter campaign name" },
                        ]}
                    >
                        <Input
                            style={{ marginTop: 15 }}
                            placeholder="Enter campaign name"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn btn-primary"
                            style={{ width: "100%", marginTop: 15 }}
                            loading={isLoading}
                        >
                            Submit
                        </Button>
                    </Form.Item>

                </Row>
            </Form>
        </div>


    </Modal>)
}