import campaignService from "services/CampaignService";
import { message } from "antd";
import synthesisService from "services/SynthesisService";

export const getCampaign = () => {
    return new Promise((resolve, reject) => {
        campaignService.getCampaigns().then((res) => {
            resolve(res)
        }
        ).catch((error) => {
            reject(error)
            message.error(error.response.data.message);
        })

    })
}

export const createCampaign = (data) => {
    return new Promise((resolve, reject) => {
        campaignService.createCampaign(data).then((res) => {
            resolve(res)
        }
        ).catch((error) => {
            reject(error)
            message.error(error.response.data.message);
        })

    })
}

export const getCampaignById = (id) => {
    return new Promise((resolve, reject) => {
        campaignService.getCampaignById(id).then((res) => {
            resolve(res)
        }
        ).catch((error) => {
            reject(error)
            message.error(error.response);
        })

    })
}


export const updateCampaign = (id, data) => {
    return new Promise((resolve, reject) => {
        campaignService.updateCampaign(id, data).then((res) => {
            resolve(res)
        }
        ).catch((error) => {
            reject(error)
            message.error(error.response.data.message);
        })

    })
}

export const synthesis = (ssmlArray) => {
    return new Promise((resolve, reject) => {
        synthesisService.getSynthesis(ssmlArray).then((res) => {
            resolve(res)
        }
        ).catch((error) => {
            reject(error)
            message.error(error.response.data.message);
        })

    })
}

export const cloneCampaign = (id) => {
    return new Promise((resolve, reject) => {
        campaignService.cloneCampaign(id).then((res) => {
            resolve(res)
        }
        ).catch((error) => {
            reject(error)
            message.error(error.response.data.message);
        })

    })
}

export const deleteCampaign = (id) => {
    return new Promise((resolve, reject) => {
        campaignService.deleteCampaign(id).then((res) => {
            resolve(res)
        }
        ).catch((error) => {
            reject(error)
            message.error(error.response.data.message);
        })

    })
}