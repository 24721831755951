import { useEffect, useState } from "react";
import profileService from "services/ProfileService";
export default function useUserData() {
  const [userData, setUserData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    profileService.getProfile().then((response)=>{
        setLoading(false)
        const profile = response;
        setUserData(response)
        localStorage.setItem("profile", JSON.stringify(profile));
        localStorage.setItem("plan_id",response?.plan_id)
        localStorage.setItem("user_id",response?.id)
    }).catch((error)=>{
        setLoading(false)
    })
  },[]);
  return {
    userData:userData,
    isLoading:isLoading
  }
}
